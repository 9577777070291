.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dashboard-container {
  display: flex;
  height: 100vh;
}

.dashboard-menu {
  flex: 0 0 10%;
  background-color: #f0f0f0;
}

.dashboard-content {
  flex: 1;
  background-color: #fff;
}

.dashboard-menu {
  background-color: #f8f8f8;
  padding: 20px;
  border-right: 1px solid #ddd;
}

.dashboard-nav {
  margin-top: 20px;
}

.dashboard-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dashboard-nav li {
  margin-bottom: 10px;
}

.dashboard-nav a {
  color: #333;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}

.dashboard-nav a:hover {
  background-color: #ddd;
}

.dashboard-nav a .icon {
  margin-right: 10px;
  font-size: 18px;
}

.dashboard-nav a.active {
  background-color: #ddd;
  font-weight: bold;
}

.dashboard-nav a.active .icon {
  color: #333;
}

.dashboard-nav a:focus {
  outline: none;
}
