.table-class {
    border-collapse: collapse;
    border: 1px solid black;
  }
  
  .table-class td,
  .table-class th {
    border: 1px solid black;
    padding: 5px;
  }

  .table-class td,
  .table-class th {
    font-size: 10px;
  }

  button{
      font-size:9px
  }