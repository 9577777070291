.table-class {
  border-collapse: collapse;
  border: 2px solid black;
  margin: 10px;
  text-align: center;
}

.table-class td,
.table-class th {
  border: 1px solid black;
  padding: 5px;
}

.table-class td,
.table-class th {
  font-size: 10px;
  width: 10px;
}



/* Estilo básico para los campos de entrada */
input[type="text"], input[type="date"] {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 1px;
  font-size: 5 px;
  /* width: 45%; */
}

/* Estilo para los contenedores de filtro */
.filters-container {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
}

.filter-group {
  flex: 1 1 calc(25% - 10px); /* Hace que cada elemento ocupe un tercio del ancho del contenedor */
  max-width: calc(25% - 10px); /* Establece un ancho máximo para cada elemento */
  margin-right: 10px; /* Añade un margen derecho de 10px a todos los elementos excepto al último */
  margin-bottom: 10px; /* Añade un margen inferior de 10px para separar las filas */
}

.filter-group:last-child {
  margin-right: 0; /* Elimina el margen derecho del último elemento para que no se desborde del contenedor */
}

/* Estilo para las etiquetas de filtro */
.filter-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

/* Estilo para el contenedor de fecha */
.date-filter-container {
  
  display: flex;
  justify-content: space-between;
}

/* Estilo para las etiquetas de fecha */
.date-filter-container label {
  flex-basis: 1%;
}
